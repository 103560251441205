<template>
	<span>
		<template v-if="type == 'customer'">
			<v-tooltip top>
				<template #activator="{ on, attrs }">
					<div v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined class="mr-3">
						<span
							:class="[
								data.to_customer && data.ranking_data
									? kpiColorIdentifier(
											data.to_customer[kpiSixMonthKey],
											data.to_customer[kpiKey],
											data.ranking_data[kpiKey],
											data.month_passed
									  )
									: '',
							]"
							>{{
								data.ranking_data && data.ranking_data[kpiKey] > 0 ? data.ranking_data[kpiKey] : "-"
							}}</span
						>
						<span>{{ data.ranking_data || data.to_customer ? "/" : "" }}</span>
						<span class="green--text">
							{{
								data.to_customer
									? data.month_passed > 6
										? data.to_customer[kpiKey] > 0
											? data.to_customer[kpiKey]
											: "-"
										: data.to_customer[kpiSixMonthKey] > 0
										? data.to_customer[kpiSixMonthKey]
										: "-"
									: "-"
							}}
						</span>
					</div>
				</template>
				<span>{{ tooltipText }}</span>
			</v-tooltip>
		</template>
		<template v-if="type == 'delhi'">
			<v-tooltip top>
				<template #activator="{ on, attrs }">
					<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
						<span
							:class="[
								data.to_customer && data.ranking_data
									? kpiColorIdentifier(
											data.to_customer[kpiSixMonthKey],
											data.to_customer[kpiKey],
											data.ranking_data[kpiKey],
											data.month_passed
									  )
									: '',
							]"
						>
							{{
								hasKey(data, "ranking_data[kpiKey]") &&
								getValue(data, "ranking_data[kpiKey]") &&
								data.ranking_data[kpiKey] > 0
									? data.ranking_data[kpiKey]
									: "-"
							}}
						</span>
						<span>{{ data.ranking_data || data.to_delhi ? "/" : "" }}</span>
						<span class="green--text">
							{{
								data.to_delhi
									? data.month_passed > 6
										? data.to_delhi[kpiKey] > 0
											? data.to_delhi[kpiKey]
											: "-"
										: data.to_delhi[kpiSixMonthKey] > 0
										? data.to_delhi[kpiSixMonthKey]
										: "-"
									: "-"
							}}
						</span>
					</v-chip>
				</template>
				<span>To Delhi</span>
			</v-tooltip>
		</template>
	</span>
</template>
<script>
import objectPath from "object-path";
import { lowerCase } from "lodash";
export default {
	name: "keywordStatus",
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			},
		},
		kpiKey: {
			type: String,
			default: "top_3",
		},
		kpiSixMonthKey: {
			type: String,
			default: "kpi_6_month_top_3",
		},
		tooltipText: {
			type: String,
			default: "To Customer",
		},
		type: {
			type: String,
			default: null,
		},
	},
	data() {
		return {};
	},
	computed: {
		keywords() {
			return this.data;
		},
	},
	methods: {
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		hasKey(object, path) {
			return objectPath.has(object, path);
		},
		kpiColorIdentifier(half_yr, full_yr, current, month_passed) {
			let color;
			let monthPassed = month_passed;
			let kpi_value;

			if (monthPassed <= 7) {
				kpi_value = half_yr * (monthPassed / 6);
			} else {
				kpi_value = full_yr * (monthPassed / 12);
			}

			if (Math.ceil(kpi_value) <= current) {
				color = "green--text";
			} else {
				color = "red--text";
			}

			return color;
		},
		idnfColor(data) {
			let monthPassed = data.month_passed;
			let final_value = 0;
			let ranking_data = data.ranking_data;
			let to_customer = data.to_customer;

			if (ranking_data && to_customer) {
				if (monthPassed <= 7) {
					if (Math.ceil(to_customer?.kpi_6_month_top_3 * (monthPassed / 6)) <= ranking_data.top3) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_5 * (monthPassed / 6)) <= ranking_data.top5) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_10 * (monthPassed / 6)) <= ranking_data.top10) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_20 * (monthPassed / 6)) <= ranking_data.top20) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_50 * (monthPassed / 6)) <= ranking_data.top50) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_100 * (monthPassed / 6)) <= ranking_data.top100) {
						final_value += 1;
					}
				} else {
					if (Math.ceil(to_customer?.top_3 * (monthPassed / 12)) <= ranking_data.top3) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_5 * (monthPassed / 12)) <= ranking_data.top5) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_10 * (monthPassed / 12)) <= ranking_data.top10) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_20 * (monthPassed / 12)) <= ranking_data.top20) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_50 * (monthPassed / 12)) <= ranking_data.top50) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_100 * (monthPassed / 12)) <= ranking_data.top100) {
						final_value += 1;
					}
				}
			}

			return final_value < 6 ? "red" : "green";
		},
		statusColor(status) {
			switch (lowerCase(status)) {
				case "active":
					return "green";
				case "hold":
					return "orange";
				case "close":
					return "red";
			}
		},
	},
};
</script>
